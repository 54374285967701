import ProcessItem from "./ProcessItem";

export default function ProcessStep({ dataTools }) {
    return (
        <section className="bg-primary">
            <div className="container">
                <div className="row">
                    <div className="col my-3 mb-lg-5">
                        <h2 className="font-weight-bold text-center text-8 text-light m-0 line-height-5 py-3">{dataTools.title}</h2>
                        <div className="row no-gutters process my-3" 
                        style={{justifyContent:""}}>
                            <ProcessItem
                                items={dataTools.passwordItem}
                                link={dataTools.passwordItem[0].link}
                                definition={dataTools.passwordDefinition}
                                title={dataTools.passwordTitle}
                                className="icons icon-location-pin text-5"
                               />
                            <ProcessItem
                                items={dataTools.ipTools}
                                link={dataTools.ipTools[0].link}
                                definition={dataTools.ipDefinition}
                                title={dataTools.ipTitle}
                                className="icons icon-user-following text-5"
                                 />
                            <ProcessItem
                                items={dataTools.otherToolsItem}
                                link={dataTools.otherToolsItem[0].link}
                                definition={dataTools.otherDefinition}
                                title={dataTools.otherToolsTitle}
                                className="icons icon-eye text-5" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
