import Link from 'next/link';
import CaretRight from '../../ui/svgs/CaretRight';

export default function ProcessItem({ items, link, definition, title, className, target }) {
    return (
        <div className="process-step-update 
        px-lg-4 py-lg-5 py-3 px-3 mb-3"
            style={{ borderColor: "#ffff", backgroundColor: "#fff" }}>
            <div className="row no-gutters"
                style={{ justifyContent: "space-between", height: "100%" }}>
                <div className="col-12 d-flex flex-column">
                    <div className="process-step-circle" style={{ alignSelf: "center" }} >
                        <strong className="process-step-circle-content">
                            <Link href={link} passHref>
                                <a className="process-item-link">
                                    <i className={className}>
                                    </i>
                                </a>
                            </Link>
                        </strong>
                    </div>
                    <div className="process-step-content d-flex flex-column"
                        style={{ alignItems: "flex-start" }}>
                        <h4 className="line-height-2 text-6 font-weight-bold"
                            style={{ alignSelf: "center" }}>
                            {title}</h4>
                        <p className="text-5 text-left text-dark line-height-3">
                            {definition} </p>

                    </div>
                </div>
                <div className="row no-gutters flex-column " style={{ justifyContent: "flex-end" }}>
                    {items.map((item, i) =>
                        <Link href={item.link} key={(Date.now() * Math.random()).toString(10)} passHref>
                            <a target={item.link.includes("https://") ? '_blank' : null}
                                rel={item.link.includes("https://") ? "noopener" : null}
                                className="process-item text-5 line-height-6 text-nav-primary">
                                <CaretRight className="mb-1 caret-right" />
                                {item.name}
                            </a>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    )
}
